import React from 'react'

//assets
// import bg from '../Assets/ProductsImages/mainbg.jpg'
// import NavBar from '../Components/GeneralComponents/NavBar'
import ProductsGeneral from '../Components/ProductsComponents/ProductsGeneral'

function ProizvodiLayout() {
  return (
    <div className='w-full   '>

       
        <div className='   flex justify-center items-center flex-col'>
        
            <div className='flex justify-center items-center  '>
                <ProductsGeneral/>
            </div>

        </div>

       
     
    </div>
  )
}

export default ProizvodiLayout
