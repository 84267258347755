
import React from 'react'

import kotlovi from '../../../Assets/ProductsImages/grejanje/kotlovi/kotlovi.jpg'
import radijator from '../../../Assets/ProductsImages/grejanje/radijatori/radijatormain.jpg'
import cevke from '../../../Assets/ProductsImages/grejanje/cevi/cevimain.png'
import podno from '../../../Assets/ProductsImages/grejanje/podno/podnomain.jpg'
import oprema from '../../../Assets/ProductsImages/grejanje/oprema/oprema.webp'
import termostat from '../../../Assets/ProductsImages/grejanje/termostati/termostat.jpg'
import pumpe from '../../../Assets/ProductsImages/grejanje/pupmpe/pumpebg.jpg'

import {motion} from 'framer-motion'
import { Link } from 'react-router-dom'

function GrejanjeMain() {
  return (
    <motion.div animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}}className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
      <Link to='/proizvodi/'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
         <h1 className='text-[32px] text-[#fff]'>Grejanje i oprema</h1>
         <p className='md:text-[18px] text-[#858585] text-center w-[300px] md:w-full '>Ugrejte svoj dom sa stilom i efikasnošću uz naše vrhunske materijale za grejanje.</p>
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


      <Link to='/proizvodi/grejanje/kotlovi'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={kotlovi} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Kotlovi </p>
                </div>
                
        </div>
        </Link>



        <Link to='/proizvodi/grejanje/radijatori'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='w-80 h-80  object-cover ' src={radijator} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Radijatori</p>
                </div>
        </div>
        </Link>




        <Link to='/proizvodi/grejanje/bakarne-cevi'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={cevke} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Bakarne cevi i fiting</p>
                </div>
        </div>
        </Link>

        <Link to='/proizvodi/grejanje/podno-grejanje'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={podno} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Podno grejanje</p>
                </div>
        </div>
        </Link>

        <Link to='/proizvodi/grejanje/radijatorska-oprema'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={oprema} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Radijatorska oprema</p>
                </div>
        </div>
        </Link>

        <Link to='/proizvodi/grejanje/termostati'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={termostat} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Termostati</p>
                </div>
        </div>
        </Link>

        <Link to='/proizvodi/grejanje/pumpe'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={pumpe} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Pumpe</p>
                </div>
        </div>
        </Link>










      </div>
    </motion.div>
  )
}

export default GrejanjeMain
