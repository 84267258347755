
import React from 'react'

import paravn from '../../../../Assets/ProductsImages/tuskabine/kabine/paravn.png'
import kada from '../../../../Assets/ProductsImages/tuskabine/kabine/kada.jpg'
import kadica from '../../../../Assets/ProductsImages/tuskabine/kabine/kadica.jpg'

import { Link } from 'react-router-dom'

function TusKabine() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/kade-tus-kabine'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Kade i tus kabine  </h1>
         <p className='text-[#858585]'>Vrste kada i tus kabina </p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     <Link to='/proizvodi/kade-tus-kabine/vise/kade'>
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-4 w-80 h-80 object-contain ' src={kada} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Tus kade</p>
                </div>
        </div>
        </Link>


       <Link to='/proizvodi/kade-tus-kabine/vise/kadice'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-contain ' src={kadica} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Tus kadice</p>
                </div>
        </div>
        </Link>

      <Link to='/proizvodi/kade-tus-kabine/vise/paravani'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={paravn} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Tus paravani</p>
                </div>
        </div>
        </Link>






      </div>
    </div>
  )
}

export default TusKabine
