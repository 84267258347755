
import React from 'react'


import bakar from '../../../../Assets/ProductsImages/grejanje/cevi/bakar.jpg'
import cu from '../../../../Assets/ProductsImages/grejanje/cevi/CU.jpg'
import { Link } from 'react-router-dom'

function GrejanjeCevi() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/grejanje'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Bakarne cevi i fiting</h1>
         <p className='text-[#858585]'>Vrste bakarnih cevi i fitinga</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


      <Link to='/proizvodi/grejanje/bakarne-cevi/cevi-vise'>
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={bakar} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Bakarne cevi</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/grejanje/bakarne-cevi/cu-fiting'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={cu} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>CU fiting</p>
                </div>
        </div>
        </Link>

        





      </div>
    </div>
  )
}

export default GrejanjeCevi
