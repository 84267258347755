import React, { useState } from 'react'

import logo from '../../Assets/MainAssets/logo.png'
import x from '../../Assets/MainAssets/iks.png'
import menu from '../../Assets/MainAssets/menu.png'

import {motion} from 'framer-motion'

import { Link, NavLink } from 'react-router-dom'

function NavBar() {

    const [toggle, setToggle] = useState(false)


  return (
    <motion.div
    animate={{
        y: [-500, 0]
      }}
      transition={{ease: 'easeInOut', duration: 0.7}}
    className=' w-full md:my-12 my-6'>
        <div className='flex container mx-auto  justify-around items-center  '>

            <Link to='/'>
            
            <div className=' py-4  '>
                <img className='w-44 md:w-80 object-contain' src={logo} alt="" />
            </div>
            </Link>

            <div className='hidden md:flex gap-20  bg-[#fff] text-[#161722]  p-16 py-4 md:text-[16px] lg:text-[17px] rounded-[50px]'>
                <Link to='/'>Početna</Link>
                <Link to='/o-nama'>O Nama</Link>
                <Link to='/proizvodi'>Proizvodi</Link>
                <Link to='/prodavnice'>Prodavnice</Link>
            </div>

            <div className='flex justify-center items-center md:hidden'>
                { !toggle ? <img className='invert w-8' src={menu} onClick={ () => { setToggle(!toggle)}} alt=''/> : <img  className='invert w-8' src={x} onClick={() => {setToggle(!toggle)}} alt=''/>}

                { toggle ? 
                    <motion.div
                    animate={{
                        x: [-500, 0]
                      }}
                      transition={{ease: 'easeInOut', duration: 0.7}}
                    className='z-10 absolute top-28 rounded-2xl text-center  left-1 right-1 bottom-32 py-12 border-2 text-[#fff] border-[#fff] space-y-6 bg-[#161722] flex flex-col justify-center items-center '>
                        <NavLink  onClick={ () => {setToggle(false)}} className=' w-1/2 border-2 px-6 py-2 bg-[#161722] rounded-3xl border-[#fff]' to='/'>Početna</NavLink>
                        <NavLink onClick={ () => {setToggle(false)}} className='w-1/2 border-2 px-6 py-2 bg-[#161722] rounded-3xl border-[#fff]' to='/o-nama'>O Nama</NavLink>
                        <NavLink onClick={ () => {setToggle(false)}} className='w-1/2 border-2 px-6 py-2 bg-[#161722] rounded-3xl border-[#fff]' to='/proizvodi'>Proizvodi</NavLink>
                        <NavLink onClick={ () => {setToggle(false)}}  className='w-1/2 border-2 px-6 py-2 bg-[#161722] rounded-3xl border-[#fff]' to='/prodavnice'>Prodavnice</NavLink>
                        <NavLink onClick={ () => {setToggle(false)}}  className='w-1/2 border-2 px-6 py-2 bg-[#161722] rounded-3xl border-[#fff]' to='/kontakt'>Kontakt</NavLink>
                    </motion.div> : 
                    
                    
                    <div></div>}

            </div>





            <div className='hidden md:flex md:text-[16px] lg:text-[17px] bg-[#161722] border-2 border-[#Fff] text-[#fff] p-16 py-4 rounded-[50px]'>
                <Link to='/kontakt'>Kontakt</Link>
            </div>
        </div>

      
    </motion.div>
  )
}

export default NavBar
