
import React from 'react'



import hajmajer from '../../../../Assets/ProductsImages/grejanje/radoprema/hajmajer.jpg'
import kalefi from '../../../../Assets/ProductsImages/grejanje/radoprema/kalefi.jpg'
import pribor from '../../../../Assets/ProductsImages/grejanje/radoprema/pribor.jpg'
import spojice from '../../../../Assets/ProductsImages/grejanje/radoprema/spojice.jpg'
import posuda from '../../../../Assets/ProductsImages/grejanje/radoprema/ekspanzija.jpg'



import { Link } from 'react-router-dom'

function GrejanjeRadOprema() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/grejanje'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Radijatorska oprema</h1>
         <p className='text-[#858585]'>Vrste radijatorske opreme</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>

 

      <Link to='/proizvodi/grejanje/radijatorska-oprema/razdelnici'>
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-4 w-80 h-80 object-cover ' src={hajmajer} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Razdelnici</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/grejanje/radijatorska-oprema/ventili'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={kalefi} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Radijatorski ventili </p>
                </div>
        </div>
        </Link>

        <Link to='/proizvodi/grejanje/radijatorska-oprema/pribor'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={pribor} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Pribor za radijatore</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/grejanje/radijatorska-oprema/spojnice-adapteri'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={spojice} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Spojnice i adapteri </p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/grejanje/radijatorska-oprema/ekspanzione-posude'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={posuda} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Ekspanzione posude</p>
                </div>
        </div>
        </Link>

        
     


        





      </div>
    </div>
  )
}

export default GrejanjeRadOprema
