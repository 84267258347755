
import React from 'react'


import wcsolja from '../../../Assets/ProductsImages/sanitarije/wcsolje/soljamain.jpg'
import monoblok from '../../../Assets/ProductsImages/sanitarije/monoblok/monoblokmain.jpg'
import kotlic from '../../../Assets/ProductsImages/sanitarije/kotlic/kotlicmain.jpg'
import umivaonik from '../../../Assets/ProductsImages/sanitarije/umivaonik/umivaonik.jpg'
import slivnici from '../../../Assets/ProductsImages/sanitarije/slinvici/slivnici.jpg'
import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'
function SanitarijaMain() {
  return (
    <motion.div animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}}className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
      <Link to='/proizvodi/'> 
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
         <h1 className='text-[32px] text-[#fff]'>Sanitarije</h1>
         <p className='md:text-[18px] text-[#858585] text-center w-[300px] md:w-full '>Opremite svoje kupatilo s našim vrhunskim sanitarijama za dom - udobnost, stil i kvalitet na jednom mjestu!</p>
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


      <Link to='/proizvodi/sanitarije/wc-solje'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={wcsolja} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>WC solje</p>
                </div>
                
        </div>
        </Link>



        <Link to='/proizvodi/sanitarije/monoblok'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='w-80 h-80  object-cover ' src={monoblok} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Monoblok</p>
                </div>
        </div>
        </Link>




        <Link to='/proizvodi/sanitarije/vodokotlici'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={kotlic} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Vodokotlici</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/sanitarije/umivaonici'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={umivaonik} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Umivaonici</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/sanitarije/linijski-slivnici'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={slivnici} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Linijski slivnici</p>
                </div>
        </div>
        </Link>


      




      </div>
    </motion.div>
  )
}

export default SanitarijaMain
