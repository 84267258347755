import React from 'react'

import icon from '../../Assets/MainAssets/logo.png'
import { Link } from 'react-router-dom'
import ig from '../../Assets/Icons/instagram.png'
import fb from '../../Assets/Icons/facebook.png'


function Footer() {
  return (
    <div className='w-full py-8 mt-12 '>
        <div className='flex justify-center  items-center container mx-auto flex-col'>
                <div className='border-b-2  py-12 w-full items-center justify-center flex'>
                    <img className='w-[300px] md:w-fill ' src={icon} alt="" />
                </div>


                

                <div className='flex justify-between md:text-[20px] flex-col md:flex-row md:gap-20 text-[#fff] md:items-start py-12 border-b-2 w-full'>
                    <div className='text-center  md:text-start space-y-6 flex items-center md:items-start justify-center flex-col border-b-2 md:border-b-0 py-12 md:py-0'>
                        <p className='text-[#fff]'>8novembar.kontakt.web@gmail.com</p>
                        <p className='text-[#fff]'>060 6655941</p>
                        <p className='text-[#fff]'>060 6655727</p>
                    </div>

                    <div className='space-y-6 flex items-center justify-center flex-col pt-12 md:pt-0'>
                        <Link className='hover:text-[#ED1D25]' to='/'>Pocetna</Link>
                        <Link className='hover:text-[#ED1D25]' to='/o-nama'>O Nama</Link>
                        <Link className='hover:text-[#ED1D25]' to='/proizvodi'>Proizvodi</Link>
                        <Link className='hover:text-[#ED1D25]' to='/prodavnice'>Prodavnice</Link>
                    </div>

                    <div className='space-y-6 flex items-center justify-center flex-col pt-12 md:pt-0'>
                        <p>Društvene mreže</p>
                        <div className='flex gap-5'>
                        <a href='https://www.instagram.com/8.novembar/'>
                        <img src={ig} alt="" />
                        </a>
                        <a href='https://www.facebook.com/8novembar.rs/'>
                        <img src={fb} alt="" />
                        </a>
                        </div>
                       
                    </div>

                    
                </div>

                <div className='py-5 text-[#fff] text-center justify-center flex'>
                    <p>© Sva prava zadrzana 8novembar.<br/> Napravio Kowy</p>
                </div>

        </div>
    </div>
  )
}

export default Footer
