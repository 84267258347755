import React from 'react'

//assets

import logo from '../../Assets/MainAssets/logo.png'
import {motion} from 'framer-motion'
function AboutMain() {
  return (
    <div className='w-full '>

   
        <div className='container mx-auto  flex justify-center items-center flex-col'>
          
      
             <div className=' flex items-center justify-center   z-100 text-[#fff] h-full  px-12 rounded-3xl'>
             <div className='text-center space-y-6 py-28'>
                <motion.h1 
                animate={{
                  x: [1000, 0]
                }}
                transition={{ease: 'easeInOut', duration: 0.7}}
                className='text-[30px]'>O nama</motion.h1>
                <motion.div
                animate={{
                  x: [1000, 0]
                }}
                transition={{ease: 'easeInOut', duration: 0.7}}
                 className='space-y-12 flex items-center justify-center flex-col'>
                  <p className='w-[250px] md:w-[600px] text-[16px] md:text-[20px] '>8.Novembar” d.o.o. je porodična firma osnovana 1993 god. i od tada neprekidno svoje poslovanje dopunjuje,širi i unapredjuje u skladu sa zahtevima tržista. Sedište preduzeća je u Ćupriji gde se i nalazi magacinski prostor od preko 500 m2 za prodaju robe na veliko.</p>
                  
                  <img className='w-64' src={logo} alt="" />
                  <p className='w-[300px] md:w-[600px] text-[16px] md:text-[20px]'>Pomocu zdrave stabilne poslovne politike stekli smo veliki broj klijenata,a što se odrazilo na poboljšanju asortimana robe pa danas naši kupci imaju na raspolaganju preko 5000 artikala i sva roba se vozi do tražene lokacije.</p>
                  <p className='w-[300px] md:w-[600px] text-[16px] md:text-[20px]'>Zaposleni u našem preduzeću imaju obavezu da izadju u susret željama naših kupaca i uz svoju stručnost, preciznost pružiti dobar savet i pravu informaciju,što predstavlja garanciju uspešne poslovne saradnje.</p>
                </motion.div>
            </div>

        </div>
        </div>

       
     
    </div>
  )
}

export default AboutMain
