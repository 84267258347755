import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Autoplay, Navigation } from "swiper";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import pestan from '../../Assets/Companies/comp1.jpg'
import minoti from '../../Assets/Companies/minotti.png'
import wilo from '../../Assets/Companies/comp3.jpg'
import gaberit from '../../Assets/Companies/comp4.png'
import inker from '../../Assets/Companies/inker.png'
import kolo from '../../Assets/Companies/kolo.jpg'
import nvmuros from '../../Assets/Companies/nvmuros.webp'
import zorka from '../../Assets/Companies/zorka.png'
import rosan from '../../Assets/Companies/rosan.png'
import alka from '../../Assets/Companies/alka.png'
import keramikaleskovac from '../../Assets/Companies/keramikaleskovac.jpg'
import hansa from '../../Assets/Companies/hansa.png'
import beko from '../../Assets/Companies/beko.jpg'
import {motion} from 'framer-motion'
function Slider() {
  return (
    <motion.div
    animate={{
        x: [-1000, 0]
      }}
      transition={{ease: 'easeInOut', duration: 0.7}}
    className='hidden md:flex flex-col justify-center items-center my-16 space-y-12 md:space-y-16'>
        <h1 className='text-[25px] md:text-[45px] text-center text-[#fff]'>Kompanije sa kojima saradjujemo</h1>
        <div className='hidden md:flex justify-center items-center container mx-auto bg-[#fff]'>
            <Swiper
        
           slidesPerView={5}
            
            autoplay={{
                delay:3000,
                disableOnInteraction: false,
                
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Navigation, Pagination]}
            className='w-screen md:w-full text-[#fff] bg-[#fff] flex items-center justify-center'>
               
                <SwiperSlide className='w-screen md:w-full md:px-12  md:py-8'>
                    <img className='w-44 h-44 md:w-80 md:h-64 md:-mr-[20px] object-contain' src={pestan} alt="" />
                </SwiperSlide>
              
                <SwiperSlide className='w-full   px-12 py-8 '>
                    <img className='w-80 h-64  object-contain' src={minoti} alt="" />
                </SwiperSlide>
     
                <SwiperSlide className='w-full   px-12 py-8'>
                    <img className='w-80 h-64  object-contain' src={keramikaleskovac} alt="" />
                </SwiperSlide>
        
                <SwiperSlide className='w-full px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={gaberit} alt="" />
                </SwiperSlide>

                <SwiperSlide className='w-full   px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={inker} alt="" />
                </SwiperSlide>
        

                <SwiperSlide className='w-full   px-12 py-8'>
                    <img className='w-80 h-64 object-contain' src={kolo} alt="" />
                </SwiperSlide>
        

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={nvmuros} alt="" />
                </SwiperSlide>

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={zorka} alt="" />
                </SwiperSlide>
        

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={rosan} alt="" />
                </SwiperSlide>

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={alka} alt="" />
                </SwiperSlide>
        
        

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={wilo} alt="" />
                </SwiperSlide>
        
                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={beko} alt="" />
                </SwiperSlide>
        
        
                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={hansa} alt="" />
                </SwiperSlide>
        
        
        
        
        
              

              
            </Swiper>

        </div>

        {/* <div className='md:hidden flex justify-center items-center container mx-auto bg-[#fff]'>
            <Swiper
        
           slidesPerView={1}
            
            autoplay={{
                delay:3000,
                disableOnInteraction: false,
                
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Navigation, Pagination]}
            className='w-screen md:w-full text-[#fff] bg-[#fff] flex items-center justify-center'>
               
                <SwiperSlide className='w-full   px-12 py-8'>
                    <img className='w-64 h-64   object-contain' src={pestan} alt="" />
                </SwiperSlide>
              
                <SwiperSlide className='w-full   px-12 py-8 '>
                    <img className='w-64 h-64  object-contain' src={minoti} alt="" />
                </SwiperSlide>
     
                <SwiperSlide className='w-full   px-12 py-8'>
                    <img className='w-80 h-64  object-contain' src={keramikaleskovac} alt="" />
                </SwiperSlide>
        
                <SwiperSlide className='w-full px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={gaberit} alt="" />
                </SwiperSlide>

                <SwiperSlide className='w-full   px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={inker} alt="" />
                </SwiperSlide>
        

                <SwiperSlide className='w-full   px-12 py-8'>
                    <img className='w-80 h-64 object-contain' src={kolo} alt="" />
                </SwiperSlide>
        

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={nvmuros} alt="" />
                </SwiperSlide>

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={zorka} alt="" />
                </SwiperSlide>
        

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={rosan} alt="" />
                </SwiperSlide>

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={alka} alt="" />
                </SwiperSlide>
        
        

                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={wilo} alt="" />
                </SwiperSlide>
        
                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={beko} alt="" />
                </SwiperSlide>
        
        
                <SwiperSlide className='w-full  px-12 py-8 '>
                    <img className='w-80 h-64 object-contain' src={hansa} alt="" />
                </SwiperSlide>
        
        
        
        
        
              

              
            </Swiper>

        </div>
       */}
    </motion.div>
  )
}

export default Slider
