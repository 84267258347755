import React from 'react'
import { Link } from 'react-router-dom'
//assets

import main from '../../Assets/ProductsImages/vodovod/vodovodmain.jpg'
import grejanjemain from '../../Assets/ProductsImages/grejanje/grejanjemain.png'
import sanitarija from '../../Assets/ProductsImages/sanitarije/sanitarijamain.jpg'
import bojler from '../../Assets/ProductsImages/bojleri/bojlermain.png'
import armature from '../../Assets/ProductsImages/armature/armaturemain.jpg'
import namestaj from '../../Assets/ProductsImages/namestaj/namestajmain.jpg'
import tus from '../../Assets/ProductsImages/tuskabine/tusmain.jpg'
import galanterija from '../../Assets/ProductsImages/galanterija/galanterijamain.jpg'
import keramika from '../../Assets/ProductsImages/keramika/keramikamain.jpg'
import rasveta from '../../Assets/ProductsImages/rasveta/rasvetamain.jpg'
import tehnika from '../../Assets/ProductsImages/belatehnika/belatehnikamain.jpg'
import {motion} from 'framer-motion'
function ProductsGeneral() {
  return (
    <motion.div 
    animate={{
      x: [1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}}

    className='container mx-auto flex justify-center items-center  p-8  gap-20 flex-wrap '>

      <div className='flex flex-col items-center justify-center md:hidden'>
        <p className='text-[#fff] text-[30px]'>Proizvodi</p>
        <Link className='text-[#818181] underline' to='/'>Idi na pocetnu stranicu</Link>
      </div>

      <Link to='/proizvodi/vodovod'>
      <div 
       
      className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className=' w-80 h-80 object-cover ' src={main} alt="" />
        <div className=" flex items-center justify-center py-1 ">
          <p>Vodovod</p>
        </div>
      </div>
      </Link>


      <Link to='/proizvodi/grejanje'>
      <div 
      
      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={grejanjemain} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Grejanje</p>
        </div>       
      </div>
      </Link>

      <Link to='/proizvodi/sanitarije'>
      <motion.div 
     
      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={sanitarija} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Sanitarije</p>
        </div>
      </motion.div>
      </Link>

      <Link to='/proizvodi/bojleri'>
      <motion.div 
     
      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={bojler} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Bojleri</p>
        </div>
      </motion.div>
      </Link>


      <Link to='/proizvodi/armature'>
      <motion.div
     
      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={armature} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Armature</p>
        </div>       
      </motion.div>
      </Link>


      <Link to='/proizvodi/kupatilski-namestaj'>
      <motion.div

      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={namestaj} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Namestaj</p>
        </div>  
      </motion.div>
      </Link>

      <Link to='/proizvodi/kade-tus-kabine'>
      <motion.div 
       
      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={tus} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Kade i tus kabine</p>
        </div>       
      </motion.div>
      </Link>

      <Link to='/proizvodi/galanterija'>
      <motion.div 
   
      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={galanterija} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Galanterija</p>
        </div>
      </motion.div>
      </Link>


      <Link to='/proizvodi/keramika'>
      <motion.div 
   
      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={keramika} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Keramika</p>
        </div>
      </motion.div>
      </Link>

      <Link to='/proizvodi/rasveta'>
      <motion.div

      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={rasveta} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Rasveta</p>
        </div>    
      </motion.div>
      </Link>


      <Link to='/proizvodi/bela-tehnika'>
      <motion.div

      className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100'>
        <img className='w-80 h-80 object-cover' src={tehnika} alt="" />
        <div className=' flex items-center justify-center py-1'>
          <p>Bela tehnika</p>
        </div> 
      </motion.div>
      </Link>


    </motion.div>
  )
}

export default ProductsGeneral
