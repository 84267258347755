
import React from 'react'


import gasni from '../../../../Assets/ProductsImages/grejanje/kotlovi/gasni.webp'
import protherm from '../../../../Assets/ProductsImages/grejanje/kotlovi/gasni/protherm.jpg'
import imergas from '../../../../Assets/ProductsImages/grejanje/kotlovi/gasni/Immergas.jpg'
import bosch from '../../../../Assets/ProductsImages/grejanje/kotlovi/gasni/bosch.jpg'

import { Link } from 'react-router-dom'

function GrejanjeKotloviGasni() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/grejanje/kotlovi'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Gasni kotlovi</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>

      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={gasni} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Valliant</p>
        </div>


        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={protherm} alt="" />
            <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Protherm</p>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={imergas} alt="" />
            <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Immergas</p>
        </div>


        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6  w-80 h-80 object-cover ' src={bosch } alt="" />
            <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Bosch</p>
        </div>

     
        





      </div>
    </div>
  )
}

export default GrejanjeKotloviGasni
