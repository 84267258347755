
import React from 'react'

import sijalica from '../../../Assets/ProductsImages/rasveta/sijalica.jpg'
import reflektori from '../../../Assets/ProductsImages/rasveta/reflektori.jpg'
import plafonjera2 from '../../../Assets/ProductsImages/rasveta/plafonjera2.jpg'
import panel from '../../../Assets/ProductsImages/rasveta/panel.png'
import ugradna from '../../../Assets/ProductsImages/rasveta/ugradna.jpg'
import nadgradna from '../../../Assets/ProductsImages/rasveta/nadgradna.jpg'
import podna from '../../../Assets/ProductsImages/rasveta/podna.jpg'
import lusteri from '../../../Assets/ProductsImages/rasveta/lusteri.jpg'
import visilica from '../../../Assets/ProductsImages/rasveta/visilica.webp'
import stonalampa from '../../../Assets/ProductsImages/rasveta/stonalampa.jpg'
import podnalampa from '../../../Assets/ProductsImages/rasveta/podnalampa.jpg'
import solarna from '../../../Assets/ProductsImages/rasveta/solarna.jpg'
import sinska from '../../../Assets/ProductsImages/rasveta/sinska.jpg'
import magnet from '../../../Assets/ProductsImages/rasveta/magnet.jpg'

import {motion} from 'framer-motion'

import { Link } from 'react-router-dom'

function RasvetaMain() {
  return (
    <motion.div animate={{
        x: [-1000, 0]
      }}
      transition={{ease: 'easeInOut', duration: 0.7}} className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Rasveta</h1>
         <p className='md:text-[18px] text-[#858585] text-center w-[300px] md:w-full '>Naša ponuda rasvete obuhvata širok spektar inovativnih i stilski dizajniranih svetiljki,<br/> lampa i osvetljenja za unutrašnje i spoljašnje prostore</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-4 w-80 h-80 object-contain ' src={sijalica} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Led sijalice</p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-contain ' src={reflektori} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Led reflektori</p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80  object-contain ' src={plafonjera2} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Plafonjere</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={panel} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Paneli</p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={ugradna} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Ugradne svetiljke </p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-8 w-80 h-80  object-cover ' src={nadgradna} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Nadgradne svetiljke </p>
                </div>
        </div>
        
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={podna} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Podne svetiljke </p>
                </div>
        </div>
     

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={lusteri} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Lusteri </p>
                </div>
        </div>
     
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={visilica} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Visilice </p>
                </div>
        </div>

        
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={stonalampa} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Stone lampe </p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={podnalampa} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Podne lampe </p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={solarna} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Solarna rasveta </p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={sinska} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Sinska rasveta </p>
                </div>
        </div>

        
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={magnet} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Magnetna rasveta </p>
                </div>
        </div>
     
     
     
     
     
     
     
     
     
     

     
     






      </div>
    </motion.div>
  )
}

export default RasvetaMain
