
import React from 'react'


import tvrda from '../../../../Assets/ProductsImages/grejanje/cevi/tvrdacevka.webp'
import meka from '../../../../Assets/ProductsImages/grejanje/cevi/meka.jpg'
import izolacija from '../../../../Assets/ProductsImages/grejanje/cevi/izolacija.jpg'

import { Link } from 'react-router-dom'

function GrejanjeCeviVise() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/grejanje/bakarne-cevi'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Bakarne cevi</h1>
         <p className='text-[#858585]'>Vrste bakarnih cevi</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={tvrda} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Bakarna cev tvrda </p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={meka} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Bakarna cev meka </p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={izolacija} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Bakarna cev u izolaciji </p>
                </div>
        </div>
     





      </div>
    </div>
  )
}

export default GrejanjeCeviVise
