
import React from 'react'


import gasni from '../../../../Assets/ProductsImages/grejanje/kotlovi/gasni.webp'
import cvrsti from '../../../../Assets/ProductsImages/grejanje/kotlovi/cvrsti.png'
import pelet from '../../../../Assets/ProductsImages/grejanje/kotlovi/pelet.webp'
import struja from '../../../../Assets/ProductsImages/grejanje/kotlovi/struja.jpg'


import { Link } from 'react-router-dom'

function GrejanjeKotlovi() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/grejanje'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Kotlovi</h1>
         <p className='text-[#858585]'>Vrste kotlova</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


      <Link to='/proizvodi/grejanje/kotlovi/gasni'>
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={gasni} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Gasni kotlovi</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/grejanje/kotlovi/cvrsto-gorivo'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={cvrsti} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Kotlovi na cvrsto gorivo</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/grejanje/kotlovi/pelet'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={pelet} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Kotlovi na pelet</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/grejanje/kotlovi/struja'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={struja} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Elektricni kotlovi</p>
                </div>
        </div>
        </Link>
     
        





      </div>
    </div>
  )
}

export default GrejanjeKotlovi
