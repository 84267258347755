
import React from 'react'


import ugradni from '../../../../Assets/ProductsImages/sanitarije/umivaonik/ugradni.png'
import nadgr from '../../../../Assets/ProductsImages/sanitarije/umivaonik/nadgradni.webp'
import { Link } from 'react-router-dom'

function SanitarijaUmivaonici() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/sanitarije/'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Umivaonici</h1>
         <p className='text-[#858585]'>Vrste umivaonika</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>



      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-contain ' src={ugradni} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Ugradni umivaonik</p>
                </div>
        </div>



    
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={nadgr} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Nadgradni umovanik</p>
                </div>
        </div>





      </div>
    </div>
  )
}

export default SanitarijaUmivaonici
