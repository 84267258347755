
import React from 'react'


import pocink from '../../../../Assets/ProductsImages/vodovod/fiting/pocink.webp'
import mesing from '../../../../Assets/ProductsImages/vodovod/fiting/mesingani.jpg'
import inox from '../../../../Assets/ProductsImages/vodovod/fiting/inox.jpeg'
import hrom from '../../../../Assets/ProductsImages/vodovod/fiting/hrom.webp'
import ppr from '../../../../Assets/ProductsImages/vodovod/fiting/ppr.webp'

import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'
function VodovodFiting() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/vodovod'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Fiting</h1>
         <p className='text-[#858585]'>Vrste fitina</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


      <Link to='/proizvodi/vodovod/fiting/ppr'>
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={ppr} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>PPR Fiting</p>
                </div>
        </div>
        </Link>

        <Link to='/proizvodi/vodovod/fiting/pocinkovani'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={pocink} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Pocinkovani</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/vodovod/fiting/mesingani'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={mesing} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Mesingani</p>
                </div>
        </div>
        </Link>

        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={inox} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Inox</p>
                </div>
        </div>


        <Link to='/proizvodi/vodovod/fiting/hrom'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={hrom} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Hrom</p>
                </div>
        </div>
        </Link>
        





      </div>
    </div>
  )
}

export default VodovodFiting
