
import React from 'react'

import geberit from '../../../../Assets/ProductsImages/sanitarije/kotlic/ugradni.jpg'
import uros from '../../../../Assets/ProductsImages/sanitarije/kotlic/alca.png'
import slovram from '../../../../Assets/ProductsImages/sanitarije/kotlic/sanit.jpg'
import pest from '../../../../Assets/ProductsImages/sanitarije/kotlic/pestan.png'

import { Link } from 'react-router-dom'

function SanitarijaVodokotUgr() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/sanitarije/vodokotlici'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Ugradni vodokotlici</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>



        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={geberit} alt="" />
              
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Geberit</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-8 w-80 h-80 object-cover ' src={uros} alt="" />
              
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Alca plast</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={slovram} alt="" />
              
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Sanit</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={pest} alt="" />
              
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Pestan</p>
        </div>



      </div>
    </div>
  )
}

export default SanitarijaVodokotUgr
