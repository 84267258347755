import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import RootLayour from "./Layouts/RootLayour";
import Home from "./Pages/Home";
import Onama from "./Pages/Onama";
import ProizvodiLayout from "./Layouts/ProizvodiLayout";
import Prodavnice from "./Pages/Prodavnice";
import VodovodMain from "./Components/ProductsComponents/Vodovod/VodovodMain";
import VodovodFiting from "./Components/ProductsComponents/Vodovod/Fiting/VodovodFiting";
import VodovodPumpe from "./Components/ProductsComponents/Vodovod/Pumpe/VodovodPumpe";
import VodovodPumpeVise from "./Components/ProductsComponents/Vodovod/Pumpe/VodovodPumpeVise";
import VodovodPotapajuce from "./Components/ProductsComponents/Vodovod/Pumpe/VodovodPotapajuce";
import VodovodHidrofori from "./Components/ProductsComponents/Vodovod/Pumpe/VodovodHidrofori";
import VodovodPumpeOprema from "./Components/ProductsComponents/Vodovod/Pumpe/VodovodPumpeOprema";
import VodovodVentili from "./Components/ProductsComponents/Vodovod/Ventili/VodovodVentili";
import VodovodKanalizacija from "./Components/ProductsComponents/Vodovod/Kanalizacija/VodovodKanalizacija";
import GrejanjeMain from "./Components/ProductsComponents/Grejanje/GrejanjeMain";
import GrejanjeKotlovi from "./Components/ProductsComponents/Grejanje/Kotlovi/GrejanjeKotlovi";
import VodovodFitingPPR from "./Components/ProductsComponents/Vodovod/Fiting/VodovodFitingPPR";
import VodovodFitingPocink from "./Components/ProductsComponents/Vodovod/Fiting/VodovodFitingPocink";
import VodovodFitingMS from "./Components/ProductsComponents/Vodovod/Fiting/VodovodFitingMS";
import VodovodFitingHrom from "./Components/ProductsComponents/Vodovod/Fiting/VodovodFitingHrom";
import GrejanjeKotloviGasni from "./Components/ProductsComponents/Grejanje/Kotlovi/GrejanjeKotloviGasni";
import GrejanjeKotloviCvrsti from "./Components/ProductsComponents/Grejanje/Kotlovi/GrejanjeKotloviCvrsti";
import GrejanjeKotloviPelet from "./Components/ProductsComponents/Grejanje/Kotlovi/GrejanjeKotloviPelet";
import GrejanjeKotloviStruja from "./Components/ProductsComponents/Grejanje/Kotlovi/GrejanjeKotloviStruja";
import GrejanjeRadijatori from "./Components/ProductsComponents/Grejanje/Radijatori/GrejanjeRadijatori";
import GrejanjeRadijatoriAlu from "./Components/ProductsComponents/Grejanje/Radijatori/GrejanjeRadijatoriAlu";
import GrejanjeRadijatoriPan from "./Components/ProductsComponents/Grejanje/Radijatori/GrejanjeRadijatoriPan";
import GrejanjeCevi from "./Components/ProductsComponents/Grejanje/BakarneCevi/GrejanjeCevi";
import GrejanjeCeviVise from "./Components/ProductsComponents/Grejanje/BakarneCevi/GrejanjeCeviVise";
import GrejanjeCeviFiting from "./Components/ProductsComponents/Grejanje/BakarneCevi/GrejanjeCeviFiting";
import GrejanjePodno from "./Components/ProductsComponents/Grejanje/Podno/GrejanjePodno";
import GrejanjePodnoCevi from "./Components/ProductsComponents/Grejanje/Podno/GrejanjePodnoCevi";
import GrejanjePodnoRazdelnici from "./Components/ProductsComponents/Grejanje/Podno/GrejanjePodnoRazdelnici";
import GrejanjePodnoKutije from "./Components/ProductsComponents/Grejanje/Podno/GrejanjePodnoKutije";
import GrejanjePodnoPribor from "./Components/ProductsComponents/Grejanje/Podno/GrejanjePodnoPribor";
import GrejanjeRadOprema from "./Components/ProductsComponents/Grejanje/RadOprema/GrejanjeRadOprema";
import GrejanjeRadOpremaRazd from "./Components/ProductsComponents/Grejanje/RadOprema/GrejanjeRadOpremaRazd";
import GrejanjeRadOpremaVent from "./Components/ProductsComponents/Grejanje/RadOprema/GrejanjeRadOpremaVent";
import GrejanjeRadOpremaPribor from "./Components/ProductsComponents/Grejanje/RadOprema/GrejanjeRadOpremaPribor";
import GrejanjeRadOpremaSpojnice from "./Components/ProductsComponents/Grejanje/RadOprema/GrejanjeRadOpremaSpojnice";
import GrejajneRadOpremaPosude from "./Components/ProductsComponents/Grejanje/RadOprema/GrejanjeRadOpremaPosude";
import GrejanjeTermostati from "./Components/ProductsComponents/Grejanje/Termostati/GrejanjeTermostati";
import GrejanjePumpe from "./Components/ProductsComponents/Grejanje/Pumpe/GrejanjePumpe";
import SanitarijaMain from "./Components/ProductsComponents/Sanitarija/SanitarijaMain";
import SanitarijaWcSolje from "./Components/ProductsComponents/Sanitarija/Solje/SanitarijaWcSolje";
import SantiarijaMonoblok from "./Components/ProductsComponents/Sanitarija/Monoblok/SantarijaMonoblok";
import SanitarijaVodokot from "./Components/ProductsComponents/Sanitarija/Vodokotlici/SanitarijaVodokot";
import SanitarijaVodokotStand from "./Components/ProductsComponents/Sanitarija/Vodokotlici/SanitarijaVodokotStand";
import SanitarijaVodokotUgr from "./Components/ProductsComponents/Sanitarija/Vodokotlici/SanitarijaVodokotUgr";
import SanitarijaUmivaonici from "./Components/ProductsComponents/Sanitarija/Umivaonici/SanitarijaUmivaonici";
import SanitarijaSlivnici from "./Components/ProductsComponents/Sanitarija/Slivnici/SanitarijaSlivnici";
import BojleriMain from "./Components/ProductsComponents/Bojleri/BojleriMain";
import Bojleri from "./Components/ProductsComponents/Bojleri/BojleriCategories/Bojleri";
import BojleriMali from "./Components/ProductsComponents/Bojleri/BojleriCategories/BojleriMali";
import BojleriSrednji from "./Components/ProductsComponents/Bojleri/BojleriCategories/BojleriSrednji";
import BojleriVeliki from "./Components/ProductsComponents/Bojleri/BojleriCategories/BojleriVeliki";
import BojleriRezervni from "./Components/ProductsComponents/Bojleri/RezervniDelovi/BojleriRezervni";
import ArmatureMain from "./Components/ProductsComponents/Armature/ArmatureMain";
import Armature from "./Components/ProductsComponents/Armature/ArmatureCategories/Armature";
import ArmatureMinoti from "./Components/ProductsComponents/Armature/ArmatureCategories/ArmatureMinoti";
import ArmatureRosan from "./Components/ProductsComponents/Armature/ArmatureCategories/ArmatureRosan";
import ArmatureOprema from "./Components/ProductsComponents/Armature/ArmatureOprema/ArmatureOprema";
import NamestajMain from "./Components/ProductsComponents/Namestaj/NamestajMain";
import TusKabineMain from "./Components/ProductsComponents/TusKabineKade/TusKabineMain";
import TusKabine from "./Components/ProductsComponents/TusKabineKade/TusKabine/TusKabine";
import TusKabineKada from "./Components/ProductsComponents/TusKabineKade/TusKabine/TusKabineKade";
import TusKabineKadice from "./Components/ProductsComponents/TusKabineKade/TusKabine/TusKabineKadice";
import TusKabineParavani from "./Components/ProductsComponents/TusKabineKade/TusKabine/TusKabineParavni";
import TusKabineOprema from "./Components/ProductsComponents/TusKabineKade/TusKabineOprema/TusKabineOprema";
import GalanterijaMain from "./Components/ProductsComponents/Galanterija/GalanterijaMain";
import KeramikaMain from "./Components/ProductsComponents/Keramika/KeramikaMain";
import Keramika from "./Components/ProductsComponents/Keramika/KeramikaCategs/Keramika";
import KeramikaMaterijal from "./Components/ProductsComponents/Keramika/KeramikaMaterijal/KeramikaMaterijal";
import RasvetaMain from "./Components/ProductsComponents/Rasveta/RasvetaMain";
import BelaTehnikaMain from "./Components/ProductsComponents/BelaTehnika/BelaTehnikaMain";
import Kontakt from "./Pages/Kontakt";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayour/>}>
        <Route index element={<Home/>}/>
        <Route path="/o-nama" element={<Onama/>}/>
        <Route path="/proizvodi" element={<ProizvodiLayout/>}/>
        <Route path="/prodavnice" element={<Prodavnice/>}/>
        <Route path="/kontakt" element={<Kontakt/>}/>




        {/* VODOVOD START */}
        <Route path="/proizvodi/vodovod" element={<VodovodMain/>}/>
        <Route path="/proizvodi/vodovod/fiting" element={<VodovodFiting/>}/>
        <Route path="/proizvodi/vodovod/fiting/ppr" element={<VodovodFitingPPR/>}/>
        <Route path="/proizvodi/vodovod/fiting/pocinkovani" element={<VodovodFitingPocink/>}/>
        <Route path="/proizvodi/vodovod/fiting/mesingani" element={<VodovodFitingMS/>}/>
        <Route path="/proizvodi/vodovod/fiting/hrom" element={<VodovodFitingHrom/>}/>
         {/* sub pumpe */}
        <Route path="/proizvodi/vodovod/pumpe" element={<VodovodPumpe/>}/>
        <Route path="/proizvodi/vodovod/pumpe-vise" element={<VodovodPumpeVise/>}/>
        <Route path="/proizvodi/vodovod/pumpe-oprema" element={<VodovodPumpeOprema/>}/>   
        <Route path="/proizvodi/vodovod/pumpe-vise/potapajuce-pumpe" element={<VodovodPotapajuce/>}/>
        <Route path="/proizvodi/vodovod/pumpe-vise/hidrofori" element={<VodovodHidrofori/>}/>
        {/* sub pumpe END */}
        <Route path="/proizvodi/vodovod/ventili" element={<VodovodVentili/>}/>
        <Route path="/proizvodi/vodovod/kanalizacija" element={<VodovodKanalizacija/>}/>
        {/* VODOVOD END */}
    
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}


        {/* GREJANJE START */}
        <Route path="/proizvodi/grejanje" element={<GrejanjeMain/>}/>
        <Route path="/proizvodi/grejanje/kotlovi" element={<GrejanjeKotlovi/>}/>
        <Route path="/proizvodi/grejanje/kotlovi/gasni" element={<GrejanjeKotloviGasni/>}/>
        <Route path="/proizvodi/grejanje/kotlovi/cvrsto-gorivo" element={<GrejanjeKotloviCvrsti/>}/>
        <Route path="/proizvodi/grejanje/kotlovi/pelet" element={<GrejanjeKotloviPelet/>}/>
        <Route path="/proizvodi/grejanje/kotlovi/struja" element={<GrejanjeKotloviStruja/>}/>


        <Route path="/proizvodi/grejanje/radijatori" element={<GrejanjeRadijatori/>}/>
        <Route path="/proizvodi/grejanje/radijatori/aluminijumski" element={<GrejanjeRadijatoriAlu/>}/>
        <Route path="/proizvodi/grejanje/radijatori/panelni" element={<GrejanjeRadijatoriPan/>}/>


        <Route path="/proizvodi/grejanje/bakarne-cevi" element={<GrejanjeCevi/>}/>
        <Route path="/proizvodi/grejanje/bakarne-cevi/cevi-vise" element={<GrejanjeCeviVise/>}/>
        <Route path="/proizvodi/grejanje/bakarne-cevi/cu-fiting" element={<GrejanjeCeviFiting/>}/>

        <Route path="/proizvodi/grejanje/podno-grejanje" element={<GrejanjePodno/>}/>
        <Route path="/proizvodi/grejanje/podno-grejanje/cevi" element={<GrejanjePodnoCevi/>}/>
        <Route path="/proizvodi/grejanje/podno-grejanje/razdelnici" element={<GrejanjePodnoRazdelnici/>}/>
        <Route path="/proizvodi/grejanje/podno-grejanje/razvodne-kutije" element={<GrejanjePodnoKutije/>}/>
        <Route path="/proizvodi/grejanje/podno-grejanje/pribor" element={<GrejanjePodnoPribor/>}/>

        <Route path="/proizvodi/grejanje/radijatorska-oprema" element={<GrejanjeRadOprema/>}/>
        <Route path="/proizvodi/grejanje/radijatorska-oprema/razdelnici" element={<GrejanjeRadOpremaRazd/>}/>
        <Route path="/proizvodi/grejanje/radijatorska-oprema/ventili" element={<GrejanjeRadOpremaVent/>}/>
        <Route path="/proizvodi/grejanje/radijatorska-oprema/pribor" element={<GrejanjeRadOpremaPribor/>}/>
        <Route path="/proizvodi/grejanje/radijatorska-oprema/spojnice-adapteri" element={<GrejanjeRadOpremaSpojnice/>}/>
        <Route path="/proizvodi/grejanje/radijatorska-oprema/ekspanzione-posude" element={<GrejajneRadOpremaPosude/>}/>

        <Route path="/proizvodi/grejanje/termostati/" element={<GrejanjeTermostati/>}/>

        <Route path="/proizvodi/grejanje/pumpe/" element={<GrejanjePumpe/>}/>
        {/* GREJANJE END */}



        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}



        {/* SANITARIJA START */}
        <Route path="/proizvodi/sanitarije" element={<SanitarijaMain/>}/>
        <Route path="/proizvodi/sanitarije/wc-solje" element={<SanitarijaWcSolje/>}/>
        <Route path="/proizvodi/sanitarije/monoblok" element={<SantiarijaMonoblok/>}/>

        <Route path="/proizvodi/sanitarije/vodokotlici" element={<SanitarijaVodokot/>}/>
        <Route path="/proizvodi/sanitarije/vodokotlici/standardni" element={<SanitarijaVodokotStand/>}/>
        <Route path="/proizvodi/sanitarije/vodokotlici/ugradni" element={<SanitarijaVodokotUgr/>}/>

        <Route path="/proizvodi/sanitarije/umivaonici" element={<SanitarijaUmivaonici/>}/>
        <Route path="/proizvodi/sanitarije/linijski-slivnici" element={<SanitarijaSlivnici/>}/>
        {/* SANITARIJA END */}

        


        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}



      {/* BOJLERI START */}
      <Route path="/proizvodi/bojleri" element={<BojleriMain/>}/>
      <Route path="/proizvodi/bojleri/bojleri-vise" element={<Bojleri/>}/>
      <Route path="/proizvodi/bojleri/bojleri-vise/malolitrazni-bojleri" element={<BojleriMali/>}/>
      <Route path="/proizvodi/bojleri/bojleri-vise/srenji-bojleri" element={<BojleriSrednji/>}/>
      <Route path="/proizvodi/bojleri/bojleri-vise/veliki-bojleri" element={<BojleriVeliki/>}/>

      <Route path="/proizvodi/bojleri/bojleri-vise/rezervni-delovi" element={<BojleriRezervni/>}/>
      {/* BOJLERI END */}

      

        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}



      {/* ARMATURE START */}
      <Route path="/proizvodi/armature" element={<ArmatureMain/>}/>
      <Route path="/proizvodi/armature/armature-vise" element={<Armature/>}/>
      <Route path="/proizvodi/armature/armature-vise/minotti" element={<ArmatureMinoti/>}/>
      <Route path="/proizvodi/armature/armature-vise/rosan" element={<ArmatureRosan/>}/>

      <Route path="/proizvodi/armature/armature-vise/oprema" element={<ArmatureOprema/>}/>
      {/* ARMATURE END */}


        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}


        {/* NAMESTAJ START */}
        <Route path="/proizvodi/kupatilski-namestaj" element={<NamestajMain/>}/>
        {/* NAMESTAJ END */}


        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}



        {/* KADE I TUS KABINE START */}
        <Route path="/proizvodi/kade-tus-kabine" element={<TusKabineMain/>}/>
        <Route path="/proizvodi/kade-tus-kabine/vise" element={<TusKabine/>}/>
        <Route path="/proizvodi/kade-tus-kabine/vise/kade" element={<TusKabineKada/>}/>
        <Route path="/proizvodi/kade-tus-kabine/vise/kadice" element={<TusKabineKadice/>}/>
        <Route path="/proizvodi/kade-tus-kabine/vise/paravani" element={<TusKabineParavani/>}/>

        <Route path="/proizvodi/kade-tus-kabine/oprema" element={<TusKabineOprema/>}/>
        {/* KADE I TUS KABINE END */}





        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {/* GALANTERIJA START */}
        <Route path="/proizvodi/galanterija" element={<GalanterijaMain/>}/>
        {/* GALANTERIJA END */}


        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <Route path="/proizvodi/keramika" element={<KeramikaMain/>}/>
        <Route path="/proizvodi/keramika/keramika-vise" element={<Keramika/>}/>
        <Route path="/proizvodi/keramika/prateci-materijal" element={<KeramikaMaterijal/>}/>

        <Route path="/proizvodi/rasveta" element={<RasvetaMain/>}/>

        <Route path="/proizvodi/bela-tehnika" element={<BelaTehnikaMain/>}/>
    </Route>
  )
)

function App() {
  return (
      <RouterProvider router={router}/>
  );
}

export default App;
