
import React from 'react'


import gasni from '../../../../Assets/ProductsImages/grejanje/kotlovi/cvrsti.png'

import { Link } from 'react-router-dom'

function GrejanjeKotloviCvrsti() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/grejanje/kotlovi'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Kotlovi na cvrsto gorivo</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>

      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={gasni} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Termomont </p>
        </div>


        





      </div>
    </div>
  )
}

export default GrejanjeKotloviCvrsti
