import React from 'react'
import ContactMain from '../Components/ContactComponents/ContactMain'

function Kontakt() {
  return (
    <div>
      <ContactMain/>
    </div>
  )
}

export default Kontakt
