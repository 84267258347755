import React from 'react'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {motion} from 'framer-motion'

function ContactMain() {

    const form = useRef();

    let inputnow = document.querySelectorAll('input')

    let deleteinput = () => {
        inputnow.value = ''
    }

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_ysfe77b', 'template_c9s8gnx', form.current, 'KXPpuXwuT9FPRtGI2')
        .then((result) => {
            console.log(result.text);
            e.target.reset()
        }, (error) => {
            console.log(error.text);
        });
    };
  return (
    <div className='flex flex-col justify-center items-center w-full py-12'>
        <form id='input1' className='text-[#fff] space-y-10 text-[17px] md:text-[20px] flex flex-col items-center md:items-start justify-center' ref={form} onSubmit={sendEmail}>
        <div className='flex flex-col md:flex-row gap-10'>
            <motion.div animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}} className='flex flex-col'>   
            <label>Ime *</label>
            <input className=' bg-transparent p-1 border-b-[2px] md:w-[350px] outline-none focus:border-b-[2px] focus:border-[red]' type="text" name="user_name" />
            </motion.div>

            
            
            <motion.div animate={{
      x: [1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}} className='flex flex-col'>
            <label>Email *</label>
            <input className=' bg-transparent p-1 border-b-[2px] md:w-[350px] outline-none focus:border-b-[2px] focus:border-[red]' type="text" name="user_email" />
            </motion.div>
       
      
        </div>

        <div className='flex flex-col md:flex-row gap-10'>
            <motion.div animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}} className='flex flex-col'>
            <label>Poruka *</label>
        <textarea style={{resize: 'none'}} className='bg-transparent p-1 border-b-[2px] w-full md:w-[740px] outline-none focus:border-b-[2px] focus:border-[red]' name="message" />
            </motion.div>

        </div>
       
       
        <motion.input animate={{
      x: [1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}} onClick={deleteinput} className='bg-[#ED1D25] py-2 px-12 rounded-xl cursor-pointer' type="submit" value="Pošalji" />
        </form>

        <div>

        </div>
    </div>
  )
}

export default ContactMain
