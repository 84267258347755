import React from 'react'

//assets
import sanitarije from '../../Assets/Services/sanitarije.png'
import grejanje from '../../Assets/Services/grejanje.png'
import vodovod2 from '../../Assets/ProductsImages/vodovod/vodovodmain.jpg'
import vodovod from '../../Assets/Services/vodovod.jpg'
import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'

function Services() {
  return (
    <div className='w-full  md:py-24 py-24'>
        <motion.div 
        animate={{
          x: [500, 0]
        }}
        transition={{ease: 'easeInOut', duration: 0.7}}className='flex container mx-auto items-center  justify-center md:justify-start text-center md:text-start'>
                 <h1 className=' mx-20 md:text-start text-center text-[30px] md:text-[45px] text-[#fff]'>Nase usluge</h1>
        </motion.div>

      <div className='container mx-auto flex items-center justify-center flex-col'>
          
         
            <motion.div 
            
            animate={{
              x: [500, 0]
            }}
            transition={{ease: 'easeInOut', duration: 0.7}}className='flex flex-col space-y-4 md:flex-row  justify-between md:gap-[600px] items-center text-[#Fff] border-b-[1px] md:py-24 py-12'>
              <motion.div  whileHover={{ scale: 1.1, }}><img className=' w-64 h-64 object-contain' src={sanitarije} alt="" /></motion.div>
              <div className='space-y-8 md:space-y-4 text-center md:text-start'>
                <p className='md:text-[36px]'>Sanitarije</p>
                <p className='text-center md:text-start md:text-[20px] md:w-[300px] lg:w-[500px] w-[300px]'>Želite li novo kupatilo? Ili želite da sprovedete davno završenu modernizaciju? U nama ćete naći kompetentnog i pouzdanog partnera.</p>
                <div>
                <Link to='/proizvodi/sanitarije'>
                <p className='underline md:text-[24px]'>Pogledaj vise &#8599;</p>
                </Link>
                </div>
           
              </div>
            </motion.div>
            
            <motion.div
            animate={{
              x: [500, 0]
            }}
            transition={{ease: 'easeInOut', duration: 0.7}}
            className='flex flex-col space-y-4 md:flex-row  justify-between md:gap-[600px] items-center text-[#Fff] border-b-[1px] md:py-24 py-12'>
              <motion.div whileHover={{ scale: 1.1, }}><img className='w-64 h-64 object-contain' src={grejanje} alt="" /></motion.div>
              <div className='space-y-8 md:space-y-4 text-center md:text-start'>
                <p className='md:text-[36px]'>Grejanje</p>
                <p className='text-center md:text-start md:text-[20px] md:w-[500px] w-[300px]'>Ugrejte svoj dom sa stilom i efikasnošću uz naše vrhunske materijale za grejanje, koji će vam omogućiti udobnost i uštedu energije tokom cele zime</p>
                <div>
                <Link to='/proizvodi/grejanje'>
                <p className='underline md:text-[24px]'>Pogledaj vise &#8599;</p>
                </Link>
                </div>
              </div>
            </motion.div>

            <motion.div 
            animate={{
              x: [500, 0]
            }}
            transition={{ease: 'easeInOut', duration: 0.7}}
            className='flex flex-col space-y-4 md:flex-row  justify-between md:gap-[600px] items-center text-[#Fff] border-b-[1px] md:py-24 py-12'>
              <motion.div whileHover={{ scale: 1.1, }}><img className='w-64 h-64 object-contain rounded-[50px]' src={vodovod2} alt="" /></motion.div>
              <div className='space-y-8 md:space-y-4 text-center md:text-start'>
                <p className='md:text-[36px]'>Vodovod</p>
                <p className='text-center md:text-start md:text-[20px] md:w-[500px] w-[300px]'>Osigurajte sigurnost i udobnost u svom domu uz naše visokokvalitetne materijale za vodovod, koji vam garantuju pouzdanost i dugotrajnost vašeg sistema vodosnabdevanja</p>
                <div>
                <Link to='/proizvodi/vodovod'>
                <p className='underline md:text-[24px]'>Pogledaj vise &#8599;</p>
                </Link>
                </div>
              </div>
            </motion.div>
      </div>
    </div>
  )
}

export default Services
