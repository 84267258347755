
import React from 'react'

import leskovac from '../../../../Assets/ProductsImages/sanitarije/wcsolje/leskovac.jpg'
import sonet from '../../../../Assets/ProductsImages/sanitarije/wcsolje/sonet.png'
import kolo from '../../../../Assets/ProductsImages/sanitarije/wcsolje/kolo.png'
import inker from '../../../../Assets/ProductsImages/sanitarije/wcsolje/inker.jpg'
import cersanit from '../../../../Assets/ProductsImages/sanitarije/wcsolje/cersanit.jpg'
import pestan from '../../../../Assets/ProductsImages/sanitarije/wcsolje/pestan.png'

import { Link } from 'react-router-dom'

function SanitarijaWcSolje() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/sanitarije'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Wc solje</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>



        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={leskovac} alt="" />
              
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Keramika Leskovac</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={sonet} alt="" />
              
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Sonet</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={kolo} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Kolo</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={inker} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Inker</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={cersanit} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Cersanit</p>
        </div>



        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={pestan} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Pestan</p>
        </div>






      </div>
    </div>
  )
}

export default SanitarijaWcSolje
