
import React from 'react'

import manometar from '../../../../Assets/ProductsImages/vodovod/pumpe/manometar.jpg'
import reducir from '../../../../Assets/ProductsImages/vodovod/pumpe/reducer.jpg'
import hidroforska from '../../../../Assets/ProductsImages/vodovod/pumpe/posuda.jpg'


import { Link } from 'react-router-dom'

function VodovodPumpeOprema() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/vodovod/pumpe'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Prateca oprema</h1>
         <p className='text-[#858585]'>Vrste pratece opreme</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={manometar} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Manometri</p>
                </div>
        </div>


        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={reducir} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Reduciri</p>
                </div>
        </div>

        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={hidroforska} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Hidroforske posude</p>
                </div>
        </div>

  




      </div>
    </div>
  )
}

export default VodovodPumpeOprema
