
import React from 'react'

import grejac from '../../../../Assets/ProductsImages/bojleri/rezerva/grejac.jpg'
import gumice from '../../../../Assets/ProductsImages/bojleri/rezerva/gumice.jpg'
import ventil from '../../../../Assets/ProductsImages/bojleri/rezerva/ventil.jpg'
import brinoksi from '../../../../Assets/ProductsImages/bojleri/rezerva/brinoks.jpg'
import { Link } from 'react-router-dom'

function BojleriRezervni() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/bojleri/'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Rezervni delovi bojlera</h1>
         <p className='text-[#858585]'>Vrste rezervnih delova za bojler</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={grejac} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Grejac za bojler</p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={gumice} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Gumice za bojler</p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={ventil} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Sigurnosni ventil</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={brinoksi} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Brinoksi</p>
                </div>
        </div>
     

     





      </div>
    </div>
  )
}

export default BojleriRezervni
