
import React from 'react'

import main from '../../../../Assets/ProductsImages/vodovod/vodovodmain.jpg'

import potapajuca from '../../../../Assets/ProductsImages/vodovod/pumpe/pumpejedan.jpg'
import wilo from '../../../../Assets/ProductsImages/vodovod/pumpe/wilopotap.jpg'
import novo from '../../../../Assets/ProductsImages/vodovod/pumpe/potapajucanovo.jpg'


import { Link } from 'react-router-dom'

function VodovodPotapajuce() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/vodovod/pumpe-vise'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
        
         <h1 className='text-[32px] text-[#fff]'>Potapajuce pumpe</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>



        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={wilo} alt="" />
  
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Wilo</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={novo} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Novoterm</p>
        </div>


        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={potapajuca} alt="" />
            
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Pedrollo</p>
        </div>

  




      </div>
    </div>
  )
}

export default VodovodPotapajuce
