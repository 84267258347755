
import React from 'react'

import stolice from '../../../Assets/ProductsImages/galanterija/stolica.jpg'

import usponskitus from '../../../Assets/ProductsImages/galanterija/usponskitus.webp'

import klizna from '../../../Assets/ProductsImages/galanterija/klizna.jpg'

import kupatilskaprostirka from '../../../Assets/ProductsImages/galanterija/kupatilskaprostirka.webp'

import nakit from '../../../Assets/ProductsImages/galanterija/nakit.webp'

import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'
function GalanterijaMain() {
  return (
    <motion.div animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}}  className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Galanterija</h1>
         <p className='md:text-[18px] text-[#858585] text-center w-[300px] md:w-full '>Naša ponuda obuhvata širok spektar kupatilske galanterije visokog kvaliteta.</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-4 w-80 h-80 object-contain ' src={stolice} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Tus stolice</p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-contain ' src={usponskitus} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Usponski tusevi</p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={klizna} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Klizne sipke</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={kupatilskaprostirka} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Kupatilske prostirke</p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={nakit} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Kupatilski nakit</p>
                </div>
        </div>
     







      </div>
    </motion.div>
  )
}

export default GalanterijaMain
