
import React from 'react'



import koleno from '../../../../Assets/ProductsImages/grejanje/cevi/koleno.jpg'
import redukcija from '../../../../Assets/ProductsImages/grejanje/cevi/redukcija.jpg'
import tkomad from '../../../../Assets/ProductsImages/grejanje/cevi/tkomad.jpg'
import kapa from '../../../../Assets/ProductsImages/grejanje/cevi/kapa.jpg'


import { Link } from 'react-router-dom'

function GrejanjeCeviFiting() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/grejanje/bakarne-cevi'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>CU fiting</h1>
         <p className='text-[#858585]'>Vrste CU fitinga</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12  w-80 h-80 object-cover ' src={koleno} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>CU koleno J/D </p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={redukcija} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>CU redukcija J/D </p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={tkomad} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>CU T komad</p>
                </div>
        </div>
     
          
 
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={kapa} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>CU Kapa / Luk / Spojnica</p>
                </div>
        </div>
     






      </div>
    </div>
  )
}

export default GrejanjeCeviFiting
