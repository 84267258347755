
import React from 'react'

import smart from '../../../../Assets/ProductsImages/armature/rosan/smart.png'
import stolz from '../../../../Assets/ProductsImages/armature/rosan/stolz.png'
import dark from '../../../../Assets/ProductsImages/armature/rosan/dark.png'
import zero from '../../../../Assets/ProductsImages/armature/rosan/zero.png'
import bro from '../../../../Assets/ProductsImages/armature/rosan/bronte.png'
import { Link } from 'react-router-dom'

function ArmatureRosan() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/armature/armature-vise'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Armature (Rosan)</h1>
         <p className='text-[#858585]'>Vrste armatura </p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-2 w-80 h-80 object-contain ' src={smart} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Smart</p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={stolz} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Stolz</p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={zero} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Zerro</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={dark} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Dark</p>
                </div>
        </div>
     

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={bro} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Brontee</p>
                </div>
        </div>







      </div>
    </div>
  )
}

export default ArmatureRosan
