
import React from 'react'

import lule from '../../../../Assets/ProductsImages/armature/oprema/lule.jpg'

import per from '../../../../Assets/ProductsImages/armature/oprema/perlator.jpg'
import ekve from '../../../../Assets/ProductsImages/armature/oprema/ekventil.jpg'
import mesac from '../../../../Assets/ProductsImages/armature/oprema/mesac.jpg'
import vm from '../../../../Assets/ProductsImages/armature/oprema/vmslavina.jpg'
import cr from '../../../../Assets/ProductsImages/armature/oprema/crevo.jpg'
import tus from '../../../../Assets/ProductsImages/armature/oprema/tus.jpg'
import { Link } from 'react-router-dom'

function ArmatureOprema() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/armature/'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Oprema za armature</h1>
         <p className='text-[#858585]'>Vrste opreme za armature</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-4 w-80 h-80 object-contain ' src={lule} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Lule</p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={mesac} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Mesaci</p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={per} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Perlatori</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={ekve} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>EK ventili</p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={vm} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Slavine VM</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={tus} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Rucice tusa</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={cr} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Tus creva</p>
                </div>
        </div>
     





      </div>
    </div>
  )
}

export default ArmatureOprema
