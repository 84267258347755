
import React from 'react'

import oprema from '../../../../Assets/ProductsImages/tuskabine/oprema.jpg'
import { Link } from 'react-router-dom'

function TusKabineOprema() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/kade-tus-kabine/'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Oprema za tus kabine</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={oprema} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Tus tockici</p>
                </div>
        </div>
     


   




      </div>
    </div>
  )
}

export default TusKabineOprema
