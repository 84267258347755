
import React from 'react'

import kontese from '../../../Assets/ProductsImages/namestaj/kontese.jpg'
import ogledala from '../../../Assets/ProductsImages/namestaj/ogledala.jpg'
import smart from '../../../Assets/ProductsImages/namestaj/mirror.jpg'
import vina from '../../../Assets/ProductsImages/namestaj/vina.jpg'

import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'
function NamestajMain() {
  return (
    <motion.div animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}} className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Kupatilski namestaj </h1>
         <p className='md:text-[18px] text-[#858585] text-center w-[300px] md:w-full '>Vrhunski dizajniran kupatilski nameštaj koji kombinuje estetiku sa praktičnošću, stvarajući savršenu atmosferu za vaše kupatilo.</p>
          
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-4 w-80 h-80 object-contain ' src={kontese} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Kontese</p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={ogledala} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Ogledala</p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={smart} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Smart Led ogledala</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={vina} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Vindabone</p>
                </div>
        </div>
     






      </div>
    </motion.div>
  )
}

export default NamestajMain
