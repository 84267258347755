
import React from 'react'


import pumpa from '../../../../Assets/ProductsImages/vodovod/pumpe/hidrofor.webp'
import oprema from '../../../../Assets/ProductsImages/vodovod/pumpe/manometar.jpg'



import { Link } from 'react-router-dom'

function VodovodPumpe() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/vodovod'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
    
         <h1 className='text-center text-[32px] text-[#fff]'>Pumpe i prateca oprema</h1>
         <p className='text-[#858585]'>Vrste pumpi i pratece opreme</p>
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


      <Link to='/proizvodi/vodovod/pumpe-vise'>
  
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  ' src={pumpa} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Pumpe</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/vodovod/pumpe-oprema'>   
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={oprema} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Oprema</p>
                </div>
        </div>
        </Link>


     



      </div>
    </div>
  )
}

export default VodovodPumpe
