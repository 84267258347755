import React from 'react'
import AboutMain from '../Components/AboutComponents/AboutMain'

function Onama() {
  return (
    <div>
      <AboutMain/>
    </div>
  )
}

export default Onama
