
import React from 'react'


import wilo from '../../../../Assets/ProductsImages/vodovod/pumpe/hidroforwilo.jpg'
import pedrolo from '../../../../Assets/ProductsImages/vodovod/pumpe/pedrolopumpa.jpg'
import novoterm from '../../../../Assets/ProductsImages/vodovod/pumpe/novoterm.jpeg'


import { Link } from 'react-router-dom'

function VodovodHidrofori() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/vodovod/pumpe-vise'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
        
         <h1 className='text-[32px] text-[#fff]'>Hidrofori</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>



        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={wilo} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Wilo</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12  w-80 h-80 object-cover ' src={novoterm} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Novoterm</p>
        </div>



   

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12  w-80 h-80 object-cover ' src={pedrolo} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Pedrolo</p>
        </div>



    
  




      </div>
    </div>
  )
}

export default VodovodHidrofori
