import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Autoplay, Navigation } from "swiper";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


//assets
import bg from '../../Assets/HomeAssets/bg.jpg'
import bg2 from '../../Assets/HomeAssets/kotao.webp'
import b2 from '../../Assets/HomeAssets/gr.jpg'

import belatehnikca from '../../Assets/MainAssets/tehnika.jpg'
import vodovod from '../../Assets/MainAssets/vodovod.jpg'
import kupatilo from "../../Assets/MainAssets/kupatilo.jpg"
import grejanjeee from "../../Assets/MainAssets/grejanje.jpg"
import rasvetaa from "../../Assets/MainAssets/rasveta.jpg"


import bg23 from '../../Assets/HomeAssets/bojler.jpg'
import rasveta from '../../Assets/HomeAssets/rasveta.webp'
import bela from '../../Assets/HomeAssets/tehnika.jpg'
import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'
function HeroSection() {
  return (
    <motion.div 
	animate={{
		x: [1000, 0]
	  }}
	  transition={{ease: 'easeInOut', duration: 0.7}}
	className='w-full '>

		<div className='hidden md:flex'>
		<Swiper
             slidesPerView={1}
            
				spaceBetween={30}
				centeredSlides={true}
				
                autoplay={{
                    delay:3000,
                    disableOnInteraction: false,
                    
                }}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				modules={[Navigation, Pagination, Autoplay]}
				className='w-[98vw]'>

				<SwiperSlide id='testic' className=' w-screen'>
					<div>
						<div className=' '>
							<img
							src={bg}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='hidden md:flex absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)]  items-center'>
						<p className='absolute top-20 left-16 md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Vas izbor je jednostavan</p>
						<p className='absolute top-32 z-10 left-16 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Mi smo ozbiljna kompanija i pruzamo vam <br/>najbolje usluge za vas i vas dom</p>
                        <Link to='/proizvodi' className='absolute top-48 left-28 md:top-[420px] md:left-80 text-[16px] md:text-[25px] bg-[#161722] text-[#fff] px-12 py-2 rounded-[50px]'>Vise</Link>
            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>
                

				<SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={b2}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] hidden md:flex items-center'>
						<p className='absolute top-20 left-[150px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Vodovod</p>
						<p className='absolute top-32 z-10 left-20 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Osigurajte čistu i sigurnu vodu uz naše<br/> pouzdane vodovodne sisteme</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>
		
		

          

				<SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={bg2}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] hidden md:flex items-center'>
						<p className='absolute top-20 left-[150px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Grejanje</p>
						<p className='absolute top-32 z-10 left-16 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Ugrejte svoj dom sa stilom i efikasnošću<br/> uz naše vrhunske materijale za grejanje</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>
                
                <SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={bela}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] hidden md:flex items-center'>
						<p className='absolute top-20 left-[130px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Bela tehnika</p>
						<p className='absolute top-32 z-10 left-20 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Opremite svoj dom uz vrhunsku <br/>belu tehniku  pouzdanost, stil i efikasnost<br/> na jednom mestu!</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>
                
				<SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={rasveta}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] hidden md:flex items-center'>
						<p className='absolute top-20 left-[160px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Rasveta</p>
						<p className='absolute top-32 z-10 left-16 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Nudimo raznolik izbor rasvete, uključujući <br/>moderne i elegantne svetiljke i osvetljenje za<br/> unutrašnje i spoljašnje prostore</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>

                   
                {/* <SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={bg23}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] flex items-center'>
						<p className='absolute top-20 left-[170px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Bojleri</p>
						<p className='absolute top-32 z-10 left-24 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Bojleri koji čine vašu svakodnevicu<br/> udobnijom - topla voda uvek<br/> na dohvat ruke!</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>
                 */}

               


			</Swiper>
		</div>

		<div className='block md:hidden'>
		<Swiper
             slidesPerView={1}
            
				spaceBetween={30}
				centeredSlides={true}
				
                autoplay={{
                    delay:3000,
                    disableOnInteraction: false,
                    
                }}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				modules={[Navigation, Pagination, Autoplay]}
				className='w-[98vw]'>


				
			<SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={kupatilo}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] hidden md:flex items-center'>
						<p className='absolute top-20 left-[150px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Grejanje</p>
						<p className='absolute top-32 z-10 left-16 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Ugrejte svoj dom sa stilom i efikasnošću<br/> uz naše vrhunske materijale za grejanje</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>

				<SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={bg2}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] hidden md:flex items-center'>
						<p className='absolute top-20 left-[130px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Bela tehnika</p>
						<p className='absolute top-32 z-10 left-20 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Opremite svoj dom uz vrhunsku <br/>belu tehniku  pouzdanost, stil i efikasnost<br/> na jednom mestu!</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>


				<SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={b2}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] hidden md:flex items-center'>
						<p className='absolute top-20 left-[150px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Vodovod</p>
						<p className='absolute top-32 z-10 left-20 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Osigurajte čistu i sigurnu vodu uz naše<br/> pouzdane vodovodne sisteme</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>
		
		


				<SwiperSlide id='testic' className=' w-screen'>
					<div>
						<div className=' '>
							<img
							src={bela}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='hidden md:flex absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)]  items-center'>
						<p className='absolute top-20 left-16 md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Vas izbor je jednostavan</p>
						<p className='absolute top-32 z-10 left-16 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Mi smo ozbiljna kompanija i pruzamo vam <br/>najbolje usluge za vas i vas dom</p>
                        <Link to='/proizvodi' className='absolute top-48 left-28 md:top-[420px] md:left-80 text-[16px] md:text-[25px] bg-[#161722] text-[#fff] px-12 py-2 rounded-[50px]'>Vise</Link>
            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>
                

		

          

                
        
                
				<SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={rasveta}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] hidden md:flex items-center'>
						<p className='absolute top-20 left-[160px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Rasveta</p>
						<p className='absolute top-32 z-10 left-16 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Nudimo raznolik izbor rasvete, uključujući <br/>moderne i elegantne svetiljke i osvetljenje za<br/> unutrašnje i spoljašnje prostore</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>

                   
                {/* <SwiperSlide id='testic' className='w-screen'>
					<div>
						<div className=' '>
							<img
							src={bg23}
							alt='test'
							id='testic'
							className=' relative top-0 right-0 left-0 bottom-0 max-h-[300px] md:min-h-[450px] lg:min-h-[600px] h-screen xl:min-h-[700px] w-[99vw] object-cover z-100'
						/>

                        <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-[99vw]  bg-[rgba(0,0,0,0.65)] flex items-center'>
						<p className='absolute top-20 left-[170px] md:top-56 md:left-80 font-bold   text-[20px] md:text-[60px] text-[#fff]'>Bojleri</p>
						<p className='absolute top-32 z-10 left-24 md:top-80 md:left-80 text-[12px] md:text-[25px] text-center md:text-start text-[#fff]'>Bojleri koji čine vašu svakodnevicu<br/> udobnijom - topla voda uvek<br/> na dohvat ruke!</p>

            		</div>
						</div>
					
				
					</div>				
				</SwiperSlide>
                 */}

               


			</Swiper>

		</div>
			
		

		</motion.div>
  )
}

export default HeroSection
