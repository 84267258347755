
import React from 'react'


import standard from '../../../../Assets/ProductsImages/sanitarije/kotlic/standard.jpg'
import ugradni from '../../../../Assets/ProductsImages/sanitarije/kotlic/ugradni.jpg'

import { Link } from 'react-router-dom'

function SanitarijaVodokot() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/sanitarije/'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Vodokotlici</h1>
         <p className='text-[#858585]'>Vrste vodokotlica</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     <Link to='/proizvodi/sanitarije/vodokotlici/standardni'>
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={standard} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Standardni vodokotlici</p>
                </div>
        </div>
        </Link>


       <Link to='/proizvodi/sanitarije/vodokotlici/ugradni'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={ugradni} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Ugradni vodokotlici</p>
                </div>
        </div>
        </Link>





      </div>
    </div>
  )
}

export default SanitarijaVodokot
