import React from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from '../Components/GeneralComponents/NavBar'
// import HeaderBar from '../Components/GeneralComponents/HeaderBar'
import Footer from '../Components/GeneralComponents/Footer'

function RootLayour() {
  return (
    <div className='w-full'>
         <div className='flex items-center justify-center  flex-col'>
            <NavBar/>
          
            <Outlet/>
            <Footer/>
          </div>
    </div>
   
  )
}

export default RootLayour
