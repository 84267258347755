
import React from 'react'


import ventil from '../../../../Assets/ProductsImages/vodovod/ventili/clasik.jpg'
import nadgradnja from '../../../../Assets/ProductsImages/vodovod/ventili/nadgradni.png'
import remerkugla from '../../../../Assets/ProductsImages/vodovod/ventili/kuglaremer.jpg'
import slavinakugla from '../../../../Assets/ProductsImages/vodovod/ventili/kuglaslavina.jpg'
import ventilnepov from '../../../../Assets/ProductsImages/vodovod/ventili/nepovratniventil.jpg'
import cist from '../../../../Assets/ProductsImages/vodovod/ventili/cist.jpg'
import poluspojnice from '../../../../Assets/ProductsImages/vodovod/ventili/poluspojnice.jpg'
import itapslavina from '../../../../Assets/ProductsImages/vodovod/ventili/itapslavina.jpg'


import { Link } from 'react-router-dom'

function VodovodVentili() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/vodovod'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Ventili</h1>
         <p className='text-[#858585]'>Vrste ventila i proizvodjaca</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>



        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={ventil} alt="" />
                <div className="  flex items-center justify-center py-2 ">
                <p>Ventili</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Stratoplus</p>
        </div>

        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={nadgradnja} alt="" />
                <div className=" flex items-center justify-center py-2 ">
                <p>Nadgradnje</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Stratoplus</p>
        </div>


        <div className='border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col  text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={slavinakugla} alt="" />
                <div className="  flex items-center justify-center py-2 ">
                <p>Kuglasta slavina</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Remer</p>
        </div>

        <div className='border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col  text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={remerkugla} alt="" />
                <div className="  flex items-center justify-center py-2 ">
                <p>Kuglasti ventil</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Remer</p>
                
        </div>

        
        <div className='border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col  text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={ventilnepov} alt="" />
                <div className="  flex items-center justify-center py-2 ">
                <p>Nepovratni ventil</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>iTap</p>
        </div>




        <div className='border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col  text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={cist} alt="" />
                <div className="  flex items-center justify-center py-2 ">
                <p>Hvatac necistoce</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>iTap</p>
        </div>




        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={poluspojnice} alt="" />
                <div className="  flex items-center justify-center py-2 ">
                <p>Poluspojnice</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>iTap</p>
        </div>

        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80 object-cover ' src={itapslavina} alt="" />
                <div className="  flex items-center justify-center py-2 ">
                <p>Kugla slavina</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>iTap</p>
        </div>







      </div>
    </div>
  )
}

export default VodovodVentili
