
import React from 'react'


import cevi from '../../../../Assets/ProductsImages/grejanje/podno/cevi.jpg'
import razdelnici from '../../../../Assets/ProductsImages/grejanje/podno/razdelnik.jpg'
import ormaric from '../../../../Assets/ProductsImages/grejanje/podno/ormaric.jpg'
import pribor from '../../../../Assets/ProductsImages/grejanje/podno/pribor.png'



import { Link } from 'react-router-dom'

function GrejanjePodno() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/grejanje'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Podno grejanje</h1>
         <p className='text-[#858585]'>Vrste materijala za podno grejanje</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>

      <Link to='/proizvodi/grejanje/podno-grejanje/cevi'>
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={cevi} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Cevi za podno grejanje</p>
                </div>
        </div>
        </Link>


      <Link to='/proizvodi/grejanje/podno-grejanje/razdelnici'>
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-4 w-80 h-80 object-cover ' src={razdelnici} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Razdelnici</p>
                </div>
        </div>
        </Link>


        <Link to='/proizvodi/grejanje/podno-grejanje/razvodne-kutije'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={ormaric} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Razvodne kutije</p>
                </div>
        </div>
        </Link>

        
        <Link to='/proizvodi/grejanje/podno-grejanje/pribor'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={pribor} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Pribor za podno grejanje</p>
                </div>
        </div>
        </Link>


        





      </div>
    </div>
  )
}

export default GrejanjePodno
