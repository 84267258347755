
import React from 'react'

import kocevi from '../../../../Assets/ProductsImages/vodovod/kanalizacija/cudnecevi.jpg'
import kacevi from '../../../../Assets/ProductsImages/vodovod/kanalizacija/kacevi.png'
import fiting from '../../../../Assets/ProductsImages/vodovod/kanalizacija/fiting.jpg'

import { Link } from 'react-router-dom'

function VodovodKanalizacija() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/vodovod'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Kanalizacija</h1>
         <p className='text-[#858585]'>Vrste kanalizacionih cevi i opreme</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>



        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={kacevi} alt="" />
                <div className="  flex items-center justify-center py-2">
                <p>Kanalizacione cevi</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Pestan</p>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-12 w-80 h-80  object-cover ' src={kocevi} alt="" />
                <div className=" flex items-center justify-center py-2">
                <p>Korugovane cevi</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Pestan</p>
        </div>


        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={fiting} alt="" />
                <div className="  flex items-center justify-center py-2 ">
                <p>Fiting</p>
                </div>
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Pestan</p>
        </div>






      </div>
    </div>
  )
}

export default VodovodKanalizacija
