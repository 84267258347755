import React from 'react'
import {motion} from 'framer-motion'
function Prodavnice() {
  return (
    <motion.div animate={{
      x: [1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}} className='w-full py-6'>
        <div className='container mx-auto flex justify-center items-center flex-col space-y-12'>
            <p className='text-[#858585]'>Prodavnice i kontakt telefoni</p>

            <div className='flex justify-center items-center flex-col text-[#fff] text-[32px]'>
                <h1>Paraćin</h1>
                <div className='flex justify-center items-center text-center flex-col space-y-4 mt-5'>
                <a href='https://goo.gl/maps/HtH961rUr4TiWE7f8' className='text-[15px] md:text-[18px] underline'>Vodovod i Grejanje / Salon Keramike / Kupatilski Nameštaj - Tome Živanovica 75 - 060 6655941</a>
                </div>
               
            </div>

            <div className='flex justify-center items-center flex-col text-[#fff] text-[32px]'>
                <h1>Ćuprija</h1>
                <div className='flex justify-center items-center text-center flex-col space-y-4 mt-5'>
                  <a href='https://goo.gl/maps/YNxQQ8hM7e8MRRMfA' className='text-[15px] md:text-[18px] underline'>Bela Tehnika - Kneza Miloša 27 - 060 6655901</a>
                  <a href='https://goo.gl/maps/nXqxbnuvBUAq12jS6' className='text-[15px] md:text-[18px] underline'>Rasveta - Kneza Miloša 9 - 060 6655902</a>   
                  <a href='https://goo.gl/maps/mh3T8kWMcahVF31C7' className='text-[15px] md:text-[18px] underline'>Salon Keramike / Kupatilski Nameštaj - Živke Damnjanović 33 - 060 6655903</a>
                  <a href='https://goo.gl/maps/AGJqFLKzCk9GbZA17' className='text-[15px] md:text-[18px] underline'>Vodovod i Grejanje - Kneza Miloša 160 - 060 6655727</a>
                </div>
      
            </div>


        </div>
    </motion.div>
  )
}

export default Prodavnice