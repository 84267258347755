
import React from 'react'

import main from '../../../Assets/ProductsImages/bojleri/bojlerislika.webp'
import rezerva from '../../../Assets/ProductsImages/bojleri/rezerva/rezervamain.jpg'
import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'
function BojleriMain() {
  return (
    <motion.div animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}}className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
      <Link to='/proizvodi/'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
         <h1 className='text-[32px] text-[#fff] text-center'>Bojleri i rezervni delovi bojlera</h1>
         <p className='md:text-[18px] text-[#858585] text-center w-[300px] md:w-full '>Kvalitetni bojleri i rezervna oprema po povoljnim cijenama, uz pouzdanu uštedu energije.</p>
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


      <Link to='/proizvodi/bojleri/bojleri-vise'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' bg-[#fff] w-80 h-80 object-contain ' src={main} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Bojleri</p>
                </div>
                
        </div>
        </Link>

        <Link to='/proizvodi/bojleri/bojleri-vise/rezervni-delovi'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={rezerva} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Rezervni delovi bojlera</p>
                </div>
                
        </div>
        </Link>







      </div>
    </motion.div>
  )
}

export default BojleriMain
