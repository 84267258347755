
import React from 'react'


import fiting from '../../../Assets/ProductsImages/vodovod/fiting.png'
import pumpe from '../../../Assets/ProductsImages/vodovod/pumpezavodu.png'
import ventil from '../../../Assets/ProductsImages/vodovod/ventil.jpg'
import kanalizacija from '../../../Assets/ProductsImages/vodovod/kanalizacija.webp'
import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'
function VodovodMain() {
  return (
    <motion.div
    animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}}
    className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
      <Link to='/proizvodi/'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
         <h1 className='text-[32px] text-[#fff]'>Vodovod</h1>
         <p className='md:text-[18px] text-[#858585] text-center w-[300px] md:w-full '>Pogledajte nasu ponudu fitinga, pumpa, ventila i kanalizacionih cevi.</p>
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


      <Link to='/proizvodi/vodovod/fiting'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={fiting} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Fiting</p>
                </div>
                
        </div>
        </Link>



        <Link to='/proizvodi/vodovod/pumpe'>
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='w-80 h-80  object-cover ' src={pumpe} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Pumpe</p>
                </div>
        </div>
        </Link>




        <Link to='/proizvodi/vodovod/ventili'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={ventil} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Ventili</p>
                </div>
        </div>
        </Link>

        <Link to='/proizvodi/vodovod/kanalizacija'>
        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={kanalizacija} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Kanalizacija</p>
                </div>
        </div>
        </Link>





      </div>
    </motion.div>
  )
}

export default VodovodMain
