import React from 'react'

import dikele from '../../Assets/MainAssets/kontakt.png'
import {Link } from 'react-router-dom'
import {motion} from 'framer-motion'
function ContactHome() {
  return (
    <motion.div 
    animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}}
    className='w-full '>
        <div className='container relative mx-auto flex justify-center items-center flex-col'>
            <div className='hidden md:flex'>
                 <img className='relative md:w-full md:h-[700px] object-contain w-[350px] h-[250px] ' src={dikele} alt="" />
            </div>


            <div className='hidden md:flex text-[#000] items-center text-center justify-center absolute md:left-36  left-16 md:top-64 top-16  flex-col md:space-y-4'>
                <p className='md:text-[56px]'>Kako vam mozemo pomoci?</p>
                <p className='md:text-[24px] text-[#696969]  w-[300px] md:w-[600px] '>  Naša služba od  će vam rado pomoći, samo nas kontaktirajte putem e-maila</p>
                <p className='hidden md:flex md:border-2 py-3 md:px-8 px-2  text-[18px] bg-[#161722] text-center rounded-3xl text-[#Fff]'><Link to='/kontakt'>Posaljite zahtev</Link></p>
            </div>

          
        </div>
      

        <div className='flex md:hidden space-y-6 text-center justify-center items-center flex-col text-[#fff]'>
            <p className='text-[30px]'>Kako vam mozemo pomoci?</p>
            <p className='w-[300px]'>Naša služba će vam rado pomoći, samo nas kontaktirajte putem e-maila</p>
            <motion.p
             whileHover={{ scale: 1.1, }}
            className='flex md:hidden md:border-2 px-8 py-2  text-[18px] bg-[#ff3e48] text-center rounded-3xl text-[#Fff]'><Link to='/kontakt'>Posaljite zahtev</Link></motion.p>
        </div>
    </motion.div>
    
  )
}

export default ContactHome
