
import React from 'react'

import mali from '../../../../Assets/ProductsImages/bojleri/mali/malimain.jpg'
import srednji from '../../../../Assets/ProductsImages/bojleri/srednji/srednjimain.jpg'
import veliki from '../../../../Assets/ProductsImages/bojleri/veliki/velikimain.jpg'


import { Link } from 'react-router-dom'

function Bojleri() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/bojleri'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Bojleri</h1>
         <p className='text-[#858585]'>Vrste bojlera</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>

  <Link to='/proizvodi/bojleri/bojleri-vise/malolitrazni-bojleri'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={mali} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Malolitrazni bojleri <br/>(5L / 8L / 10L)</p>
                </div>
                
        </div>
        </Link>

        <Link to='/proizvodi/bojleri/bojleri-vise/srenji-bojleri'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 bg-[#fff] h-80 object-cover ' src={srednji} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p className=''>Srednji bojleri <br/>(50L / 80L / 100L / 120L)</p>
                </div>
                
        </div>
        </Link>


        <Link to='/proizvodi/bojleri/bojleri-vise/veliki-bojleri'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 h-80 object-cover ' src={veliki} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Veliki bojleri<br/> (200L / 250L / 300L)</p>
                </div>
                
        </div>
        </Link>




      </div>
    </div>
  )
}

export default Bojleri
