
import React from 'react'
import lepak from '../../../../Assets/ProductsImages/keramika/lepak.jpg'
import podloga from '../../../../Assets/ProductsImages/keramika/podloga.jpg'
import maz from '../../../../Assets/ProductsImages/keramika/premaz.png'
import kajlica from '../../../../Assets/ProductsImages/keramika/kajlice.jpg'
import nivelator from '../../../../Assets/ProductsImages/keramika/nivelator.jpg'
import traka from '../../../../Assets/ProductsImages/keramika/traka.jpg'
import { Link } from 'react-router-dom'

function KeramikaMaterijal() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/keramika'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Prateci materijal</h1>
         <p className='text-[#858585]'>Vrste pratece opreme za keramiku</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff]  w-80 h-80 object-cover ' src={lepak} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Lepak</p>
                </div>
        </div>
     


 
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={podloga} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Podloge</p>
                </div>
        </div>

        
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  p-4 object-cover ' src={maz} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Premazi</p>
                </div>
        </div>

              
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={kajlica} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Kajlice</p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={nivelator} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Nivelatori</p>
                </div>
        </div>


        
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={traka} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Trake</p>
                </div>
        </div>

        
     
     





      </div>
    </div>
  )
}

export default KeramikaMaterijal
