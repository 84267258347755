
import React from 'react'

import picolo from '../../../../Assets/ProductsImages/armature/minoti/picolo.webp.jpg'
import ven from '../../../../Assets/ProductsImages/armature/minoti/veneto.jpg'
import eva from '../../../../Assets/ProductsImages/armature/minoti/eva.jpg'
import prim from '../../../../Assets/ProductsImages/armature/minoti/prima.webp'
import stan from '../../../../Assets/ProductsImages/armature/minoti/standard.jpg'
import qua from '../../../../Assets/ProductsImages/armature/minoti/quadro.jpg'
import ugr from '../../../../Assets/ProductsImages/armature/minoti/ugradne.jpg'
import viv from '../../../../Assets/ProductsImages/armature/minoti/viva.jpg'
import { Link } from 'react-router-dom'

function ArmatureMinoti() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/armature/armature-vise'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Armature (Minotti)</h1>
         <p className='text-[#858585]'>Vrste armatura </p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-2 w-80 h-80 object-contain ' src={picolo} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Picolo</p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80 object-cover ' src={ven} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Veneto</p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={eva} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Eva</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={prim} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Prima</p>
                </div>
        </div>
     

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={stan} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Standard</p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={qua} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Quadro</p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={ugr} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Ugradne</p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={viv} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Viva</p>
                </div>
        </div>
     


     





      </div>
    </div>
  )
}

export default ArmatureMinoti
