
import React from 'react'

import minot from '../../../../Assets/Companies/minotti.png'
import rosan from '../../../../Assets/Companies/rosan.png'
import { Link } from 'react-router-dom'

function Armature() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/armature'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Armature</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>

  <Link to='/proizvodi/armature/armature-vise/minotti'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 p-2 bg-[#fff] h-80 object-contain ' src={minot} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Minotii</p>
                </div>
                
        </div>
        </Link>

        <Link to='/proizvodi/armature/armature-vise/rosan'>
        <div className=' border-2 border-[#ffffff] relative  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className=' w-80 bg-[#fff] h-80 object-contain ' src={rosan} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p className=''>Rosan</p>
                </div>
                
        </div>
        </Link>





      </div>
    </div>
  )
}

export default Armature
