import React from 'react'
import HeroSection from '../Components/HomeComponents/HeroSection'
import Slider from '../Components/HomeComponents/Slider'
import Services from '../Components/HomeComponents/Services'
import ContactHome from '../Components/HomeComponents/ContactHome'
// import Vreme from '../Components/HomeComponents/Vreme'

function Home() {
  return (
    <div className=''>
      <HeroSection/>
      {/* <Vreme/> */}
      <Slider/>
      <Services/>
      <ContactHome/>
    </div>
  )
}

export default Home
