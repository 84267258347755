
import React from 'react'

import sporet from '../../../Assets/ProductsImages/belatehnika/sporet.png'
import frizider from '../../../Assets/ProductsImages/belatehnika/frizider.jpg'
import zamrzivac from '../../../Assets/ProductsImages/belatehnika/zamrzivacc.jpg'
import vesmasina from '../../../Assets/ProductsImages/belatehnika/vesmasina.png'
import aparati from '../../../Assets/ProductsImages/belatehnika/aparati.jpg'

import {motion} from 'framer-motion'

import { Link } from 'react-router-dom'

function BelaTehnikaMain() {
  return (
    <motion.div animate={{
      x: [-1000, 0]
    }}
    transition={{ease: 'easeInOut', duration: 0.7}} className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Bela Tehnika</h1>
         <p className='md:text-[18px] text-[#858585] text-center w-[300px] md:w-full '>Opremite svoj dom vrhunskom belom tehnikom koja vam pruža praktičnost,<br/> efikasnost i pouzdanost u svakodnevnim zadacima.</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>


     
      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-4 w-80 h-80 object-contain ' src={vesmasina} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Ves masine</p>
                </div>
        </div>
     


       
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-contain ' src={frizider} alt="" />
                <div className="  flex items-center justify-center py-1 ">
                <p>Frizderi</p>
                </div>
        </div>
      

      
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80  object-contain ' src={zamrzivac} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Zamrzivaci</p>
                </div>
        </div>

        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-contain ' src={sporet} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Sporeti</p>
                </div>
        </div>
     
        <div className=' border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col space-y-2 text-[20px] text-[#fff] transition-all delay-100 '>
            <img className='bg-[#Fff] w-80 h-80  object-cover ' src={aparati} alt="" />
                <div className=" flex items-center justify-center py-1 ">
                <p>Mali kucni aparati </p>
                </div>
        </div>
    
     
     
     
     
     
     
     

     
     






      </div>
    </motion.div>
  )
}

export default BelaTehnikaMain
