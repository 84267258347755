
import React from 'react'


import pocink from '../../../../Assets/ProductsImages/vodovod/fiting/pocink.webp'
import mesing from '../../../../Assets/ProductsImages/vodovod/fiting/mesingani.jpg'
import inox from '../../../../Assets/ProductsImages/vodovod/fiting/inox.jpeg'
import hrom from '../../../../Assets/ProductsImages/vodovod/fiting/hrom.webp'
import ppr from '../../../../Assets/ProductsImages/vodovod/fiting/ppr.webp'

import { Link } from 'react-router-dom'

function VodovodFitingMS() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/vodovod/fiting'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
     
        </Link>
    
         <h1 className='text-[32px] text-[#fff]'>Mesingani fiting</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>

      <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={mesing} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Stratoplus</p>
        </div>


        <div className='border-2 border-[#ffffff]  flex justify-center items-center text-center flex-col relative text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-6 w-80 h-80 object-cover ' src={mesing} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Arco</p>
        </div>

      
      

        





      </div>
    </div>
  )
}

export default VodovodFitingMS
