
import React from 'react'


import mali from '../../../../Assets/ProductsImages/bojleri/veliki/velikimain.jpg'

import { Link } from 'react-router-dom'

function BojleriVeliki() {
  return (
    <div className='w-full '>
      <div className='flex justify-center items-center flex-col space-y-2'>
        <Link to='/proizvodi/bojleri/bojleri-vise'>
        <p className='underline text-[#Fff]'>Idi nazad</p>
        </Link>
        
         <h1 className='text-[32px] text-[#fff]'>Veliki bojleri</h1>
         <p className='text-[#858585]'>Vrste proizvodjaca</p>
      
      </div>
   

      <div className='container mx-auto flex justify-center items-center flex-wrap gap-20 p-8'>




        <div className=' border-2 border-[#ffffff] relative flex justify-center items-center text-center flex-col text-[20px] text-[#fff]  transition-all delay-100 '>
            <img className='bg-[#Fff] p-8 w-80 h-80 object-cover ' src={mali} alt="" />
                
                <p className='absolute text-[#red] bg-[#284ea1] left-0 top-0 p-1 px-5'>Elit Cacak</p>
        </div>



   

    
  




      </div>
    </div>
  )
}

export default BojleriVeliki
